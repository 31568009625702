import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  IconButton
} from "@mui/material";
import CustomPDFViewer from "./CustomPDFViewer";
import DownloadIcon from '@mui/icons-material/Download';
const extractYouTubeId = (url) => {
  const match = url.match(
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([^"&?\/\s]{11})/
  );
  return match ? match[1] : null;
};

export default function ResourceModel({ open, onClose, resourceUrl, dowloadbuttonShow }) {
  const isYouTube =
    resourceUrl &&
    (resourceUrl?.includes("youtube.com/watch") ||
      resourceUrl?.includes("youtu.be/"));
  const isPDF = resourceUrl && resourceUrl?.endsWith(".pdf");
  const isImage =
    resourceUrl &&
    (resourceUrl?.endsWith(".jpg") ||
      resourceUrl?.endsWith(".jpeg") ||
      resourceUrl?.endsWith(".png"));

  const videoId = isYouTube ? extractYouTubeId(resourceUrl) : null;
  const embedUrl = videoId ? `https://www.youtube.com/embed/${videoId}` : "";
  let userName = localStorage.getItem("userName") || "Labcity";
  const [iframeRef, setIframeRef] = React.useState(null);

  const downloadResource = async (url) => {
    if (!url) return;    
    try {
      // Fetch the file first to handle potential CORS issues
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      // Extract filename from URL and decode URI components
      let filename = url.substring(url.lastIndexOf('/') + 1);      
      // Handle URL encoded characters like %20 (space)
      try {
        filename = decodeURIComponent(filename);
      } catch (e) {
        console.warn("Failed to decode filename", e);
      }      
      // Create download link and click it
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();      
      // Clean up
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
      }, 100);
    } catch (error) {
      console.error("Download failed:", error);
      
      // Fallback method if fetch fails
      window.open(url, '_blank');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {dowloadbuttonShow && (
      <IconButton 
        size="small"
        sx={{
          position: "absolute",
          right: 70,
          top: 3,
          height: "25px",
          minWidth: "40px",
        }}
        onClick={() => downloadResource(resourceUrl)}
      >
        <DownloadIcon/>
      </IconButton>
      )}
      
      <Button
        variant="contained"
        size="small"
        color="error"
        sx={{
          position: "absolute",
          right: 25,
          top: 3,
          height: "25px",
          minWidth: "40px",
        }}
        onClick={onClose}
      >
        X
      </Button>
      <br />
      <DialogContent
        style={{ margin: "10px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
      >
        {resourceUrl ? (
          isYouTube ? (
            <iframe
              title="Video"
              width="100%"
              height="450px"
              src={embedUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : isPDF ? (
            <WatermarkedContent userName={userName}>
            <CustomPDFViewer url={resourceUrl} />
            </WatermarkedContent>
          ) : isImage ? (
            <WatermarkedContent userName={userName}>
            <img
              src={resourceUrl}
              alt="Resource"
              style={{ width: "100%", height: "100%" }}
                onContextMenu={(e) => e.preventDefault()}
            />
            </WatermarkedContent>
          ) : (
            <Typography variant="h6">Unsupported resource type</Typography>
          )
        ) : (
          <Typography variant="h6">No resource URL provided</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}

const WatermarkedContent = ({children, userName}) => {
  return (
    <div style={{ position: "relative", width: "100%", height: "500px" }}>
      {children}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#D2CDCF",
          fontSize: "4rem",
          fontFamily: "Arial",
          fontWeight: "bold",
          textAlign: "center",
          zIndex: 999,
          transform: "rotate(336deg)",
        }}
      >
        {userName}
      </div>
    </div>
  );
};
