import React from "react";
import {
  Box,
  Button,
  Modal,
  Stack,
  Typography,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

export const CSRFFormData = [
  {
    question:
      "Calibration work to be carried out",
    options: ["Lab", "Site"],
    hasDetails: true,
    hasPoints: false,
  },
  {
    question: "Calibration adjustments to be carried out",
    options: ["Yes", "No"],
    hasDetails: true,
    hasPoints: false,
  },
  {
    question: "Calibration due date to be specified",
    options: ["Yes", "No"],
    hasDetails: true,
    hasPoints: false,
  },
  {
    question:
      "Method of Calibrations are carried out",
    options: ["laboratory Defined", "User Defined"],
    hasDetails: true,
    hasPoints: false,
  },
  {
    question: "Calibration Certificate accuracy Limits are suggested as per",
    options: ["Manufacturer Recommended", "User Defined","National / International recommended"],
    hasDetails: false,
    hasPoints: false,
  },
  {
    question: "Any Deviation From Contract work",
    // options: ["Yes", "No"],
    hasDetails: true,
    hasPoints: false,
  },
  {
    question:
      "Date of delivery",
    // options: ["Yes", "No"],
    hasDetails: true,
    hasPoints: false,
  },
  {
    question: "NABL symbol is using for only accredited scope range",
    // options: ["Yes", "No"],
    hasDetails: true,
    hasPoints: false,
  },
  {
    question:
      "Decision Rule For Conformity Statement (Tick any one as per your requirement)\nTaking Uncertainty into Account (ILAC G8 Decision Rule)",
    options: ["a) The measurement result plus or minus the expanded uncertainty (95% C.L.)  does not exceed the specification limit  then compliance with specification can be stated - Pass / Within Specifications",
       "b) The measurement result plus or minus the expanded uncertainty (95% C.L.) exceeds the specification limit, then non-compliance with specification can be stated - Fail / Outside Specifications",
      "c) The measurement result plus or minus the expanded uncertainty (95% C.L.) does not exceed but overlaps the specification limit, it is not possible to state compliance or non-compliance. Therefore, the result is conditional compliance - Conditional Pass / Pass with overlap",
      "d) The measurement result plus or minus the expanded uncertainty (95% C.L.) exceeds but overlaps the specification limit, it is not possible to state compliance or non-compliance. Therefore, the result is conditional non-compliance.- Conditional Fail / Fail with overlap."
      ],
    hasDetails: false,
    hasPoints: false,
  },
  // {
  //   question: "Details / Decision rule",
  //   options: ["Pass", "Fail"],
  //   options1: ["In-tolerence", "Out of tolerence"],
  //   hasoptions: true,
  //   hasDetails: false,
  //   hasPoints: false,
  // },
  {
    question: " Decision rule not required ( Customer Request ) ",
    options: ["Yes", "No"],
    hasDetails: true,
    hasPoints: false,
  },
  {
    question:
      "Reviewed by (Manpower,Availability of standards,if any specific requirements)",
    // options: ["Yes", "No"],
    hasDetails: true,
    hasPoints: false,
  },
  {
    question:
      "Arrow instruments calibration shall make it clear in all its contracts with customers that its activities falling under accredited scope in no way imply that the product so tested or equipment calibrated is approved by NABL.",
    // options: ["Yes", "No"],
    hasDetails: true,
    hasPoints: false,
  },
  // {
  //   question:
  //     "Any special request for preparation, packing & dispatch of material?",
  //   options: ["Yes", "No"],
  //   hasDetails: false,
  //   hasPoints: false,
  // },
  // {
  //   question: "NOTE:",
  //   points: [
  //     "A. Customers are requested to refer the CSRF No. as mentioned above for all clarification / correspondence.",
  //     "B. All possible care will be taken in handling & operating the equipment",
  //     "C. Company is not responsible for any damage caused to the equipment during transit arranged by customer.",
  //   ],
  //   hasDetails: false,
  //   hasPoints: true,
  // },
  // {
  //   question:
  //     "Mode of Delivery & Collection Preference",
  //   options: ["Drop & Pick-Up by Custome", "Pick-Up by Customer Designated External Provider", "Company to Send by Courier"],
  //   hasDetails: false,
  //   hasPoints: false,
  // },
];
const modalCSRFStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: 600,
  bgcolor: "background.paper",
  border: "2px solid cadetblue",
  padding: "5px",
  fontSize: "13px",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  overflowY: "scroll",
};

export default function CSRFForm({
  pushCArray,
  latestSRFId,
  setCSRFFormPayload,
  CSRFFetchData,
}) {

  const handleCSRFOpen = () => {
    if (CSRFFetchData) {
      const updatedCSRFformState = CSRFFormData.map((item, index) => {
        const fetchedQuestion = CSRFFetchData[`quession${index + 1}`];
        const { answer, details } = fetchedQuestion ? JSON.parse(fetchedQuestion) : { answer: "", details: "" };
        return {
          ...item,
          answer,
          details,
        };
      });
      setCSRFformState(updatedCSRFformState);

      // const question16 = CSRFFetchData.quession16 ? JSON.parse(CSRFFetchData.quession16) : {};
      // setQuest16({
      //   reference_1: question16.reference_1 || "",
      //   reference_2: question16.reference_2 || "",
      //   reference_3: question16.reference_3 || "",
      //   reference_4: question16.reference_4 || "",
      //   laboratory_1: question16.laboratory_1 || "",
      //   laboratory_2: question16.laboratory_2 || "",
      //   laboratory_3: question16.laboratory_3 || "",
      //   laboratory_4: question16.laboratory_4 || "",
      //   engineer_1: question16.engineer_1 || "",
      //   engineer_2: question16.engineer_2 || "",
      //   engineer_3: question16.engineer_3 || "",
      //   engineer_4: question16.engineer_4 || "",
      // });

    //   const question17 = CSRFFetchData.quession17 ? JSON.parse(CSRFFetchData.quession17) : {};
    // setQuest17({
    //   drop_pickup: question17.drop_pickup || false,
    //   pickup_by_external_provider: question17.pickup_by_external_provider || false,
    //   jrpm_send_by_courier: question17.jrpm_send_by_courier || false,
    // });
    }
    setModalOpenCSRF(true);
  };
  const handleCSRFClose = () => {
    let payload = {};

    CSRFformState.forEach((question, index) => {
      payload[`quession${index + 1}`] = JSON.stringify({
        answer: question.answer,
        details: question.details,
      });
    });
    // payload["quession16"] = JSON.stringify({
    //   ...quest16,
    // });
    // payload["quession17"] = JSON.stringify({
    //   ...quest17,
    // });
    payload["srfId"] = latestSRFId;
    setCSRFFormPayload(payload);
    setModalOpenCSRF(false);
  };
  const [modalOpenCSRF, setModalOpenCSRF] = React.useState(false);
  const [CSRFformState, setCSRFformState] = React.useState(
    CSRFFormData.map((item) => ({ answer: "", details: "", ...item }))
  );
  const [quest16, setQuest16] = React.useState({
    reference_1: "",
    reference_2: "",
    reference_3: "",
    reference_4: "",
    laboratory_1: "",
    laboratory_2: "",
    laboratory_3: "",
    laboratory_4: "",
    engineer_1: "",
    engineer_2: "",
    engineer_3: "",
    engineer_4: "",
  });
  const [quest17, setQuest17] = React.useState({
    drop_pickup: false,
    pickup_by_external_provider: false,
    jrpm_send_by_courier: false,
  });

  const handlequetInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    if (type === "checkbox") {
      setQuest17({
        ...quest17,
        [id]: checked,
      });
    } else {
      setQuest16({
        ...quest16,
        [id]: value,
      });
    }
  };

  const handleCheckboxChange = (index, optionIndex) => (event) => {
    const updatedCSRFformState = [...CSRFformState];
    updatedCSRFformState[index].answer =
      updatedCSRFformState[index].options[optionIndex];
    if (updatedCSRFformState[index].hasoptions && optionIndex === 1) {
      updatedCSRFformState[index].details =
        updatedCSRFformState[index].options1[0];
    } 
    // else {
    //   updatedCSRFformState[index].details = "";
    // }

    setCSRFformState(updatedCSRFformState);
  };

  const handleOptionOrDetailsChange = (index, optionIndex) => (event) => {
    const updatedCSRFformState = [...CSRFformState];
    if (optionIndex !== undefined) {
      updatedCSRFformState[index].details =
        updatedCSRFformState[index].options1[optionIndex];
    } else {
      updatedCSRFformState[index].details = event.target.value;
    }
    setCSRFformState(updatedCSRFformState);
  };

  const renderCSRFModal = () => {
    return (
      <Modal
        open={modalOpenCSRF}
        onClose={handleCSRFClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalCSRFStyle}>
          <table style={{ border: "none" }}>
            <tbody>
              {CSRFformState.map((item, index) => (
                <tr key={index}>
                  {!item.hasPoints && (
                    <td
                      style={{
                        width: "40%",
                        padding: "0px 5px",
                        // border: "none",
                        verticalAlign:"top",
                      }}
                    >
                      <Typography variant="span">
                        {index + 1}.{item.question}
                      </Typography>
                    </td>
                  )}

                  {item.hasPoints && (
                    <td
                      style={{
                        width: "40%",
                        padding: "0px 5px",
                        // border: "none",
                        verticalAlign:"top",
                      }}
                    >
                      <Typography variant="span">
                        <b>
                          {index + 1}.{item.question}
                        </b>
                      </Typography>
                      {item.points?.map((option, index) => (
                        <Typography
                          variant="caption"
                          display="block"
                          key={index}
                        >
                          {option}
                        </Typography>
                      ))}
                    </td>
                  )}
                  <td style={{verticalAlign:"top",width:"60%",padding:"0px 5px"}}>
                    {item.options?.map((option, optionIndex) => (
                      <span key={optionIndex}>
                        <Checkbox
                          size="small"
                          sx={{ m: "0px", p: "0px 5px" }}
                          checked={item.answer === option}
                          onChange={handleCheckboxChange(index, optionIndex)}
                        />
                        {option}
                      </span>
                    ))}
                  {item.hasDetails && (
                    <TextField
                      variant="standard"
                      value={item.details}
                      onChange={handleOptionOrDetailsChange(index)}
                      sx={{ width:"100%",margin:"2px 0px"}}
                      inputProps={{style: {fontSize: "12px"}}}
                    />
                    )}
                  {item.hasoptions && (
                    <div
                      style={{
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item.options1.map((option, optionIndex) => (
                        <span key={optionIndex}>
                          <Checkbox
                            size="small"
                            sx={{ m: "0px", p: "0px 5px" }}
                            checked={item.details === option}
                            onChange={handleOptionOrDetailsChange(
                              index,
                              optionIndex
                            )}
                          />
                          {option}
                        </span>
                      ))}
                    </div>
                  )}
                  {item.hasPoints && (
                    <div
                      style={{
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          Certificate to be issued in the name of:
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label="Age"
                          value={item.details}
                          onChange={handleOptionOrDetailsChange(index)}
                        >
                          {pushCArray?.map((option) => (
                            <MenuItem key={option.label} value={option.label}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
          {/* <table
            style={{
              border: "none",
              margin: "2px 5px",
              padding: "5px",
            }}
          >
            <tbody style={{ padding: "5px" }}>
              <tr style={{ marginTop: "5px" }}>
                <td
                  colSpan={2}
                  style={{
                    textAlign: "left",
                    border: "none",
                  }}
                >
                  <Typography variant="span">
                    <b>16.Mode of Delivery & Collection Preference</b>
                  </Typography>
                </td>
                <td style={{ border: "none" }}>
                  <Stack direction="row" spacing={2}>
                    <input
                      type="checkbox"
                      id="drop_pickup"
                      checked={quest17.drop_pickup}
                      onChange={handlequetInputChange}
                    />
                    <b>Drop & Pick-Up by Customer</b>
                  </Stack>
                </td>
                <td style={{ border: "none" }}>
                  <Stack direction="row" spacing={2}>
                    <input
                      type="checkbox"
                      id="pickup_by_external_provider"
                      checked={quest17.pickup_by_external_provider}
                      onChange={handlequetInputChange}
                    />
                    <b>Pick-Up by Customer Designated External Provider</b>
                  </Stack>
                </td>
                <td style={{ border: "none" }}>
                  <Stack direction="row" spacing={2}>
                    <input
                      type="checkbox"
                      id="jrpm_send_by_courier"
                      checked={quest17.jrpm_send_by_courier}
                      onChange={handlequetInputChange}
                    />
                    <b>Company to Send by Courier</b>
                  </Stack>
                </td>
              </tr>
            </tbody>
          </table> */}
        </Box>
      </Modal>
    );
  };
  return (
    <>
      <div style={{ marginTop: "20px", textAlign: "left" }}>
        <Button size="small" variant="contained" onClick={handleCSRFOpen}>
          CSRF Form
        </Button>
      </div>
      {renderCSRFModal()}
    </>
  );
}
