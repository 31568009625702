import { MAX_COLS } from "../../../global";
import autoTable from "jspdf-autotable";
import { printcertificateConfigData, printCertificateConfiguration } from "./printcertificateConstant";
import { customFontFamily, footerConfig, printCertificateSection, totalHeaderHeightWithCalibrationLableorNot } from "./certificateConfig";

let serialNoShow = printCertificateSection.readingTableSerialNoColumnShow;
let processedPages = new Set(); // Set to keep track of processed pages
let firstSkippedPage = null; // To track the first page that should be skipped

export async function drawTables(
  pdfDoc,
  y,
  certificateTables,
  readingRows,
  props
) {
  const { margin, tableMargin, initPage } = props;
  const tablePartition = printcertificateConfigData.certificateDetailsConfig?.tablePartition; // Check table partition setting

  y += 1;
  // format table data
  certificateTables = certificateTables?.filter((table) => table.status == 1);

  let groupedReadingRows = {};
  readingRows.forEach((row) => {
    if (!groupedReadingRows[row.tableId]) {
      groupedReadingRows[row.tableId] = [];
    }
    groupedReadingRows[row.tableId].push(row);
  });

  // Get page width for side-by-side tables
  const pageWidth = printCertificateConfiguration.pageWidth;
  const tableWidth =
    (pageWidth -
      (tableMargin.left + tableMargin.right) -
      (tablePartition ? 10 : 0)) /
    (tablePartition ? 2 : 1);

  // Process tables differently based on tablePartition
  if (tablePartition) {
    // Process tables one by one, checking column count for each
    for (let i = 0; i < certificateTables.length; i++) {
      let currentTable = certificateTables[i];
      let nextTable =
        i + 1 < certificateTables.length ? certificateTables[i + 1] : null;

      // Count columns for current table
      const currentTableColumns =
        Array.from(
          { length: MAX_COLS },
          (_, j) => currentTable[`c${j + 1}`]
        )?.filter((x) => x).length +
        (currentTable?.isUncertainty ? 1 : 0) +
        (currentTable?.isComplianceStatus ? 1 : 0) +
        (serialNoShow ? 1 : 0);

      // Count columns for next table if it exists
      let nextTableColumns = 0;
      if (nextTable) {
        nextTableColumns =
          Array.from(
            { length: MAX_COLS },
            (_, j) => nextTable[`c${j + 1}`]
          )?.filter((x) => x).length +
          (nextTable?.isUncertainty ? 1 : 0) +
          (nextTable?.isComplianceStatus ? 1 : 0) +
          (serialNoShow ? 1 : 0);
      }

      // Check if both tables have 8 or fewer columns and can be displayed side by side
      const canShowSideBySide =
         (currentTableColumns + nextTableColumns) <= 8 && nextTable !== null;
      if (canShowSideBySide) {
        // First, test if BOTH tables will fit on the current page
        const leftTableFitTest = await testTableFit(
          pdfDoc,
          y,
          currentTable,
          groupedReadingRows[currentTable.id] || [],
          {
            ...props,
            tableMargin: {
              left: tableMargin.left,
              right: tableMargin.left + tableWidth + 5,
              top: tableMargin.top,
              bottom: tableMargin.bottom,
            },
            tableWidth: tableWidth,
          }
        );

        const rightTableFitTest = await testTableFit(
          pdfDoc,
          y,
          nextTable,
          groupedReadingRows[nextTable.id] || [],
          {
            ...props,
            tableMargin: {
              left: tableMargin.left + tableWidth + 10,
              right: tableMargin.right,
              top: tableMargin.top,
              bottom: tableMargin.bottom,
            },
            tableWidth: tableWidth,
          }
        );

        // If either table doesn't fit, add a new page
        if (!leftTableFitTest || !rightTableFitTest) {
          await printCertificateConfiguration.pdfDoc.addPage();
          y = totalHeaderHeightWithCalibrationLableorNot.total + 5;
        }

        // Draw left table
        let leftY = await drawSingleTable(
          pdfDoc,
          y,
          currentTable,
          groupedReadingRows[currentTable.id] || [],
          {
            ...props,
            tableMargin: {
              left: tableMargin.left,
              right: tableMargin.left + tableWidth + 5,
              top: tableMargin.top,
              bottom: tableMargin.bottom,
            },
            tableWidth: tableWidth,
            preventTableBreak: true,
          }
        );

        // Draw right table
          const rightY = await drawSingleTable(
            pdfDoc,
            y,
            nextTable,
            groupedReadingRows[nextTable.id] || [],
            {
              ...props,
              tableMargin: {
                left: tableMargin.left + tableWidth + 10,
                right: tableMargin.right,
                top: tableMargin.top,
                bottom: tableMargin.bottom,
              },
              tableWidth: tableWidth,
              preventTableBreak: true,
            }
          );

         // Update y position based on the maximum of both tables
        y = Math.max(leftY, rightY) + 10;

        // Skip the next table since we've already processed it
        i++;
      } else {
        // Draw current table with full width
        const fullY = await drawSingleTable(
          pdfDoc,
          y,
          currentTable,
          groupedReadingRows[currentTable.id] || [],
          {
            ...props,
            tableWidth: pageWidth - (tableMargin.left + tableMargin.right),
            preventTableBreak: false,
          }
        );

        // Update y position for next table
        y = fullY + 10;
      }
    }
  } 
  else {
    // Original single-table approach
    for (let i = 0; i < certificateTables.length; i++) {
      let table = certificateTables[i];
      const updatedY = await drawSingleTable(
        pdfDoc,
        y,
        table,
        groupedReadingRows[table.id] || [],
        props
      );
      y = updatedY + 10;
    }
  }
  
  processedPages.clear();
  return y;
}

// Helper function to test if a table will fit on the current page without actually drawing it
async function testTableFit(pdfDoc, y, table, tableReadings, props) {
  // Calculate estimated table height based on number of rows
  const rowCount = tableReadings.length;
  const headerRowCount = table?.defaultConfiguration?.showHeader
    ? table?.defaultConfiguration?.commonHeaderConfig
      ? 2
      : 1
    : 0;

  // Rough estimation: each row is approximately 15 points high, header rows are 20 points
  const estimatedTableHeight = headerRowCount * 15 + rowCount * 15; // Add some margin

  // Calculate remaining space on the page
  const remainingSpace =
    printCertificateConfiguration.pageHeight -
    footerConfig.footerimgHeight -
    footerConfig.footertableHeight -
    footerConfig.footerandbodygap -
    y -
    10;
  // Table won't fit if estimated height exceeds remaining space
  return estimatedTableHeight <= remainingSpace;
}

// Helper function to draw a single table
async function drawSingleTable(pdfDoc, y, table, tableReadings, props) {
  const { margin, tableMargin, initPage, tableWidth, preventTableBreak } =
    props;
    const tableName = table.tableName;
    let columnRelations = table?.defaultConfiguration?.relations;
    let totalColumns = null;

    let header = null;
    let columns = Array.from({ length: MAX_COLS }, (_, i) => table[`c${i + 1}`])
      ?.filter((x) => x)
      .map((x) => x?.split("#")[0]);

    if (table?.defaultConfiguration?.showHeader) {
      if (table?.isUncertainty) {
        columns.push("Expanded Uncertainty (±)");
      }
      if (table?.isComplianceStatus) {
        columns.push("Compliance Status");
      }
      if (serialNoShow) {
        columns.unshift("Sr.No.");
      }

      totalColumns = columns.length;
      header = [];
      // process common header configuration
      if (table?.defaultConfiguration?.commonHeaderConfig) {
        let commonHeaderConfig = table?.defaultConfiguration?.commonHeaderConfig;
        if (serialNoShow) {
          commonHeaderConfig = "$:1," + commonHeaderConfig;
        }
        let i = 0;
        let commonHeaderRow = commonHeaderConfig.split(",").map((config) => {
          const [label, span] = config.split(":");
          if (label === "$") {
            let cell = {
              content: columns[i],
              colSpan: parseInt(span, 10),
              rowSpan: 2,
              styles: { halign: "center", valign: "bottom" },
            };
            columns[i] = "";
            i++;
            return cell;
          } else {
            i += parseInt(span, 10);
            return {
              content: label,
              colSpan: parseInt(span, 10),
            };
          }
        });
        if (commonHeaderRow.some((row) => row.content !== "")) {
          header.push(commonHeaderRow);
        }
        if (table?.isUncertainty || table?.isComplianceStatus) {
          const totalColSpan = commonHeaderRow.reduce(
            (acc, row) => acc + row.colSpan,
            0
          );
          if (totalColSpan < totalColumns) {
            commonHeaderRow.push("");
          }
        }
      }

      // filter empty columns
      columns = columns.filter((x) => x);

      header.push(columns);
    } else {
      if (serialNoShow) {
        columns.unshift("Sr.No.");
      }
      totalColumns = columns.length;
    }

    // const tableReadings = groupedReadingRows[table.id] || [];
    let globalIndex = 1;
    const tableRows = tableReadings.map((reading) => {
      let row = [];
      let index = globalIndex; // Start with the globalIndex

      // parse header rows
      if (reading["c1"]?.includes("_rh_")) {
        row = [
          { content: reading["c1"].split("_rh_")[1], colSpan: totalColumns },
          ...Array.from({ length: totalColumns - totalColumns }, (_, i) => ""),
        ];
        globalIndex = 1; // Reset the index after unit row
      }
      // parse unit row
      else if (reading["c1"]?.includes("_unit_")) {
        row = Array.from(
          {
            length:
              totalColumns -
              (table?.isUncertainty ? 1 : 0) -
              (table?.isComplianceStatus ? 1 : 0) -
              (serialNoShow ? 1 : 0),
          },
          (_, i) =>
            reading[columnRelations[`c${i + 1}`]]?.replaceAll("_unit_", "")
        );
        if (table?.isUncertainty) {
          row.push(reading?.uncertainty?.replaceAll("_unit_", ""));
        }
        if (table?.isComplianceStatus) {
          row.push("");
        }
        globalIndex = 1; // Reset the index after unit row
        // pass serialNoShow column is an empty string
        if (serialNoShow) {
          row.unshift("");
        }
      }
      //   parse reading rows
      else {
        row = Array.from(
          {
            length:
              totalColumns -
              (table?.isUncertainty ? 1 : 0) -
              (table?.isComplianceStatus ? 1 : 0) -
              (serialNoShow ? 1 : 0),
          },
          (_, i) =>
            reading[columnRelations[`c${i + 1}`]]
              ?.split("#")[0]
              ?.replaceAll("$", "")
              ?.replaceAll("\t", "")
        );
        if (table?.isUncertainty) {
          row.push(reading?.uncertainty?.split("#")[0]?.replaceAll("$", "")?.replaceAll("~", ""));
        }
        if (table?.isComplianceStatus) {
          row.push(reading?.complianceStatus == 1 ? "Pass" : "Fail");
        }
        if (serialNoShow) {
          row.unshift(index);
        }
        globalIndex++; // Increment the global index after each row
      }

      return row;
    });

    // Print the table name
    if (
        y + 10 >
        printCertificateConfiguration.pageHeight -
          footerConfig.footerimgHeight -
          footerConfig.footertableHeight -
          footerConfig.footerandbodygap -
          10
      ) {
        await printCertificateConfiguration.pdfDoc.addPage();
        y = totalHeaderHeightWithCalibrationLableorNot.total + 5;
      }
      if (tableName?.trim()) {
        y += 5;
        pdfDoc.setFontSize(printCertificateConfiguration.fontSizes.h2);
        pdfDoc.setFont(customFontFamily?.boldFont,"bold");
        pdfDoc.text(tableName?.trim(), tableMargin.left, y);
        y += 5;
      }

        let fontSize;
        if (totalColumns < 10) {
          fontSize = printCertificateConfiguration.fontSizes.h2;
        } else if (totalColumns < 15) {
          fontSize = printCertificateConfiguration.fontSizes.h4;
        } else if (totalColumns < 20) {
          fontSize = printCertificateConfiguration.fontSizes.h5;
        } else {
          fontSize = printCertificateConfiguration.fontSizes.h6;
        }

    // Generate the table in the new page
    autoTable(pdfDoc, {
      startY: y,
      margin: tableMargin,
      tableWidth: tableWidth,
      head: header,
      body: tableRows,
      theme: "grid",
      headStyles: {
        fillColor: false,
        textColor: printCertificateConfiguration.textColor,
        fontSize: fontSize,
        font: customFontFamily?.boldFont,
      },
      styles: {
        halign: "center",
        fillColor: false,
        textColor: printCertificateConfiguration.textColor,
        lineColor: printCertificateConfiguration.textColor,
        lineWidth: printCertificateConfiguration.lineWidth,
        fontSize: fontSize,
        font: customFontFamily?.normalFont,
        cellPadding: printCertificateConfiguration.cellPaddingTable,
      },
      // Prevent table from spanning multiple pages if preventTableBreak is true
      startPageNumber: preventTableBreak
        ? pdfDoc.internal.getNumberOfPages()
        : undefined,
      pageBreak: preventTableBreak ? "avoid" : "auto",
      didDrawPage: (data) => {
        const currentPage = pdfDoc.internal.getNumberOfPages(); // Get the current page
        // If the first skipped page is not set, skip the first page that appears
        if (firstSkippedPage === null) {
          firstSkippedPage = currentPage; // Mark this page to be skipped first
          return; // Skip processing this page
        }
        // If the current page is the first skipped page, skip it
        if (currentPage === firstSkippedPage) {
          return; // Skip processing this page
        }
        if (data.pageNumber > 1 && processedPages.has(currentPage)) {
          initPage();
          return;
        }
        // Process all other pages and skip any repeated pages
        if (!processedPages.has(currentPage) ) {
          processedPages.add(currentPage);
          initPage();
        }
      },
    });

  return pdfDoc.autoTable.previous.finalY;
}
