var metric, imperial;

metric = {
  m2_s: {
    name: {
      singular: "Square meter per second",
      plural: "Square meters per second",
    },
    to_anchor: 1,
  },
  St: {
    name: {
      singular: "Stokes",
      plural: "Stokes",
    },
    to_anchor: 0.0001,
  },
  cSt: {
    name: {
      singular: "Centistokes",
      plural: "Centistokes",
    },
    to_anchor: 0.000001,
  },
};

imperial = {
  ft2_s: {
    name: {
      singular: "Square foot per second",
      plural: "Square feet per second",
    },
    to_anchor: 0.092903,
  },
  in2_s: {
    name: {
      singular: "Square inch per second",
      plural: "Square inches per second",
    },
    to_anchor: 0.00064516,
  },
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "m2_s",
      ratio: 1 / 0.092903, // 1 m²/s = 10.76391 ft²/s
    },
    imperial: {
      unit: "ft2_s",
      ratio: 0.092903,
    },
  },
};
