import React, { useContext, useState } from "react";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DatasheetContext } from "./datasheet.context";
import { convertUnit } from "../../uncertainties/utils";
import moment from "moment";

export default function StandardMasterSelection(props) {
  const {
    index,
    label,
    standardRanges,
    updateStandardRangesOfRow,
    isSupportive,
    valId,
    rowIndex,
    table,
  } = props;
  const datasheetContextValue = useContext(DatasheetContext);
  const {
    state: {
      staticReadingRows,
      datasheetDetails,
      selectedMastersIds,
      readingsGroupByDetails,
    },
  } = datasheetContextValue;
  const [autoSelectStatus, setAutoSelectStatus] = useState(true);
  // Standard range filtration
  const newRanges = standardRanges.filter((rangeObj) => {
    // Validate pre-requisites
    if (!staticReadingRows || !table?.defaultConfiguration?.rangeCol) {
      return false;
    }

    // Separate minimum range and maximum range
    let [minRange, maxRange] = rangeObj.range.split("|").map((part) => {
      const [value, unit] = part.split("#");
      return { value: parseFloat(value), unit };
    });

    // If only minimum range is available, set maximum range to minimum range value
    let maxRangeValue =
      !maxRange?.value || isNaN(maxRange.value) || maxRange.value === ""
        ? minRange.value
        : maxRange.value;

    // Find range column index
    let rangeCol = table?.defaultConfiguration?.rangeCol;

    // Find Latest unit and RH Row index
    let immediateParentUnitRowIndex = -1;
    let immediateParentRhRowIndex = -1;

    for (let i = rowIndex; i >= 0; i--) {
      const value = staticReadingRows[i]["c1"];
      if (value?.includes("_unit_") && immediateParentUnitRowIndex === -1) {
        immediateParentUnitRowIndex = i;
      }
      if (value?.includes("_rh_") && immediateParentRhRowIndex === -1) {
        immediateParentRhRowIndex = i;
      }
      // Break the loop if both indices are found
      if (
        immediateParentUnitRowIndex !== -1 &&
        immediateParentRhRowIndex !== -1
      ) {
        break;
      }
    }

    // Extract groupBYValue
    const groupBYValue =
      immediateParentRhRowIndex > -1
        ? readingsGroupByDetails?.valuesPerRow?.[immediateParentRhRowIndex]
        : "";

    // Parse groupBYValue into a key-value object
    const groupBYValueObj = groupBYValue
      ? Object.fromEntries(
          groupBYValue
            .split(", ")
            .map((pair) => pair.split(": ").map((s) => s.trim())),
        )
      : {};

    // Ensure etParameter matches when groupBYValue is available
    if (groupBYValue) {
      if (groupBYValueObj.mode) {
        if (groupBYValueObj.etParameter !== rangeObj.etParameter) {
          return false;
        }
      } else {
        if (groupBYValueObj.parameter !== rangeObj.parameter) {
          return false;
        }
      }
    }

    // Get latest unit and reading value
    const readingValue = parseFloat(staticReadingRows?.[rowIndex]?.[rangeCol]);
    const readingUnit =
      staticReadingRows?.[immediateParentUnitRowIndex]?.[rangeCol]?.split(
        "_unit_",
      )?.[1];

    // If standard range unit and reading unit are different, convert unit
    if (readingUnit !== minRange.unit || readingUnit !== maxRange.unit) {
      minRange.value = convertUnit(minRange.value, minRange.unit, readingUnit);
      maxRangeValue = convertUnit(maxRangeValue, maxRange.unit, readingUnit);
      minRange.unit = readingUnit; // Update the unit of minRange
    }

    return (
      readingValue >= minRange.value &&
      readingValue <= maxRangeValue &&
      readingUnit === minRange.unit
    );
  });

  // Precompute selected master IDs once to avoid repeated processing
  const selectedMasterIdsArray =
    selectedMastersIds?.split(",")?.map(Number) || [];

  // Apply standard filtration logic
  const stdFilteration = table?.defaultConfiguration?.stdFilteration;

  // Filter ranges for selected masters
  const filterRangesByMasters = (ranges) =>
    ranges?.filter((rangeObj) =>
      selectedMasterIdsArray.includes(rangeObj.id),
    ) || [];

  // Filter ranges
  const sameRangeForOtherMasters = filterRangesByMasters(newRanges);
  const filterStandardRangesByMaster = filterRangesByMasters(standardRanges);

  const updatedRanges =
    sameRangeForOtherMasters.length > 0 ? sameRangeForOtherMasters : newRanges;
  const filterUpdatedRangesForMasters = filterRangesByMasters(updatedRanges);

  // Determine final filtered ranges
  const filteredRanges =
    stdFilteration && filterUpdatedRangesForMasters.length > 0
      ? filterUpdatedRangesForMasters
      : filterStandardRangesByMaster;

  const autoSelectStandardMasterValue = () => {
    if (
      filterUpdatedRangesForMasters?.length > 0 &&
      label === "Standard master 1" &&
      filterUpdatedRangesForMasters?.length <= standardRanges?.length &&
      autoSelectStatus
    ) {
      const value = {
        id: filteredRanges?.[0]?.id,
        stId: filteredRanges?.[0]?.stId,
        title: filteredRanges?.[0]?.title,
        rangeId: filteredRanges?.[0]?.rangeId,
        range: filteredRanges?.[0]?.range?.replaceAll("#", ""),
        accuracy: filteredRanges?.[0]?.accuracy?.replaceAll("#", ""),
        lc: filteredRanges?.[0]?.lc?.replaceAll("#", ""),
        mode: filteredRanges?.[0]?.mode,
        parameter: filteredRanges?.[0]?.parameter,
        etParameter: filteredRanges?.[0]?.etParameter,
        paratype: filteredRanges?.[0]?.paratype,
        dsKey: filteredRanges?.[0]?.dsKey,
      };

      // Call update function when the condition is met
      updateStandardRangesOfRow(
        `${value.id}:${value.rangeId}`,
        isSupportive ? -1 : index + 1,
      );

      return value;
    }

    return undefined;
  };

  return (
    <div>
      <Autocomplete
        fullWidth
        id="checkboxes-tags-demo"
        size="small"
        options={
          filteredRanges != null && selectedMastersIds
            ? filteredRanges?.map((item) => {
                return {
                  id: item.id,
                  stId: item.stId,
                  title: item.title,
                  rangeId: item.rangeId,
                  range: item.range?.replaceAll("#", ""),
                  accuracy: item.accuracy?.replaceAll("#", ""),
                  lc: item.lc?.replaceAll("#", ""),
                  uniformity: item.uniformity?.replaceAll("#", ""),
                  stability: item.stability?.replaceAll("#", ""),
                  mode: item.mode,
                  parameter: item.parameter,
                  etParameter: item.etParameter,
                  paratype: item.paratype,
                  dsKey: item.dsKey,
                };
              })
            : []
        }
        value={
          valId && selectedMastersIds
            ? filteredRanges
                ?.filter((stdr) => valId === "" || valId == stdr.rangeId)
                .map((stdr) => {
                  if (stdr === "") return undefined;
                  if (isSupportive) {
                    return {
                      id: stdr.id,
                      stId: stdr.stId,
                      title: stdr.title,
                      rangeId: stdr.rangeId,
                      range: stdr.range?.replaceAll("#", ""),
                      uniformity: stdr.uniformity?.replaceAll("#", ""),
                      stability: stdr.stability?.replaceAll("#", ""),
                    };
                  }
                  return {
                    id: stdr.id,
                    stId: stdr.stId,
                    title: stdr.title,
                    rangeId: stdr.rangeId,
                    range: stdr.range?.replaceAll("#", ""),
                    accuracy: stdr.accuracy?.replaceAll("#", ""),
                    lc: stdr.lc?.replaceAll("#", ""),
                    mode: stdr.mode,
                    parameter: stdr.parameter,
                    etParameter: stdr.etParameter,
                    paratype: stdr.paratype,
                    dsKey: stdr.dsKey,
                  };
                })[0]
            : // :undefined
              autoSelectStandardMasterValue()
        }
        getOptionLabel={(option) => {
          return `${option.stId} : ${option.title}, ${`Range : ${
            option.range || ""
          }`}, ${
            !isSupportive
              ? `Accuracy: ${option.accuracy || ""}`
              : `Uniformity: ${option.uniformity || ""}`
          }, ${
            !isSupportive
              ? `LC : ${option.lc || ""}`
              : `Stability : ${option.stability || ""}`
          }, ${!isSupportive ? `Parameter: ${option.parameter || ""}` : ""}, ${
            !isSupportive && option.dsKey == "0"
              ? `Mode : ${option.mode || ""}`
              : ""
          }, ${
            !isSupportive && option.dsKey == "0"
              ? `ET Parameter: ${option.etParameter || ""}`
              : ""
          }, ${
            !isSupportive && option.dsKey == "0"
              ? `Parameter Type : ${option.paratype || ""}`
              : ""
          }`;
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{ fontSize: "11px" }}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.stId} <b>:</b> {option.title}, &nbsp;&nbsp;
            <span style={{ color: "	#335FFF" }}>
              Range: {option.range},&nbsp;&nbsp;
            </span>
            {isSupportive && (
              <span style={{ color: "#335FFF" }}>
                Uniformity: {option.uniformity},&nbsp;&nbsp;
              </span>
            )}
            {!isSupportive && (
              <span style={{ color: "#148F77" }}>
                Accuracy: {option.accuracy},&nbsp;&nbsp;
              </span>
            )}
            {!isSupportive && (
              <span style={{ color: "	#FF5533" }}>
                LC: {option.lc},&nbsp;&nbsp;
              </span>
            )}
            {isSupportive && (
              <span style={{ color: "	#FF5533" }}>
                Stability: {option.stability},&nbsp;&nbsp;
              </span>
            )}
            {!isSupportive && option.dsKey == "0" ? (
              <>
                <span style={{ color: "	#335FFF" }}>
                  Mode: {option.mode},&nbsp;&nbsp;
                </span>
                <span style={{ color: "	#148F77" }}>
                  ET Parameter: {option.etParameter},&nbsp;&nbsp;
                </span>
                <span style={{ color: "	#FF5533" }}>
                  Parameter Type: {option.paratype},&nbsp;&nbsp;
                </span>
              </>
            ) : (
              <>
                <span style={{ color: "	#148F77" }}>
                  Parameter: {option.parameter},&nbsp;&nbsp;
                </span>
              </>
            )}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder="select multiple standards"
          />
        )}
        onChange={(event, value) => {
          if (value === null) {
            setAutoSelectStatus(false);
            updateStandardRangesOfRow("", isSupportive ? -1 : index + 1);
          } else {
            updateStandardRangesOfRow(
              value ? `${value?.id}:${value?.rangeId}` : "",
              isSupportive ? -1 : index + 1,
            );
          }
        }}

        // disableCloseOnSelect
      />
    </div>
  );
}
