export const mode = ["Source", "Measure"];

export const parameters = {
  Source: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Oscilloscope",
    "Temperature Simulation",
    "AC Power @",
    "DC Power",
    "Conductivity meter",
    "pH Meter",
    "AC High voltage",
    "DC High voltage",
    "Power factor", 
    "Active energy" ,
    "High resistance",
    "Gauss" 
  ],
  Measure: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "AC Power @",
    "DC Power",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Temperature Simulation",
    "AC / DC High Voltage",
    "Time",
    "AC / DC High Current (By Direct)",
    "AC / DC High Current (By Clamp)",
    "AC High voltage",
    "DC High voltage",
    "Power factor", 
    "Active energy" ,
    "High resistance",
    "Gauss" 
  ],
};

export const paratype = {
  Source: {
    Oscilloscope: [
      "Amplitude",
      "AC Amplitude @ 50 Hz",
      "DC Amplitude",
      "Time Base",
      "Frequency",
      "AC Amplitude @ 1 kHz",
    ],
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD(PT - 100)",
      "L-Type",
      "C-Type",
    ],
  },
  Measure: {
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD(PT - 100)",
      "L-Type",
      "C-Type",
    ],
  },
};

export let gaugeArr = [
  { id: 1, label: "Plain plug Gauge" },
  { id: 2, label: "Width gauge" },
  { id: 3, label: "Paddle gauge" },
  { id: 4, label: "Flush pin" },
  { id: 5, label: "Snap gauge" },
];

export const otherParamter = [
  { label: "Contact", value: 1 },
  { label: "Non Contact", value: 2 },
  { label: "Squarness of Exterior Faces over Width H", value: 3 },
  { label: "Flatness", value: 4 },
  { label: "Parallelism", value: 5 },
  { label: "Squareness of end Faces w.r.t exterior faces over total length L", value: 6,
  },
  { label: "Coaxiality ", value: 7 },
  { label: "Perpendicularity ", value: 8 },
  { label: "Pitch", value: 9 },
  { label: "Flank Angle", value: 10 },
  { label: "Angle Measurement", value: 11 },
  { label: "Linear Dimension (X-Y Axis)", value: 12 },
  { label: "Magnification", value: 13 },
  { label: "Lack of trueness of rotation between centers", value: 14 },
  { label: "Variation in diameter", value: 15 },
  { label: "Aperture Size", value: 16 },
  { label: "Aperture Size & Wire Diameter", value: 17 },
  { label: "Centre Distance", value: 18 },
  { label: "Setting Angle", value: 19 },
  { label: "Coaxiality of Dead Center", value: 20 },
  { label: "Parallelism of working surface", value: 21 },
  { label: "Straightness", value: 22 },
  { label: "Symmetricity Error", value: 23 },
  { label: "Parallelism of opposite face", value: 24 },
  { label: "Parallelism of V Flank", value: 25 },
  { label: "Tension Mode", value: 26 },
  { label: "Compression Mode", value: 27 },
  { label: "Pull", value: 28 },
  { label: "Push", value: 29 },
  { label: "Linear", value: 30 },
  { label: "Setting Stick", value: 31 },
  { label: "Length", value: 32 },
  { label: "Earth Resistance", value: 33 },
  { label: "Insulation Resistance", value: 34 },
  { label: "Earth Leakage", value: 35 },
  { label: "Patient Leakage", value: 36 },
  { label: "Enclosure Leakage", value: 37 },
  { label: "Probe", value: 38 },
];

export const fixedColumns = [
  [
    {
      field: "Action",
      headerName: "Action",
    },
    {
      field: "id",
      headerName: "Sr. No.",
      editable: false,
    },
    {
      field: "mode",
      headerName: "Mode",
      editable: true,
      toggle: 1,
    },
    {
      field: "etParameter",
      headerName: "ETParameter",
      editable: true,
      toggle: 1,
    },
    {
      field: "paratype",
      headerName: "Parameter Type",
      editable: true,
      toggle: 1,
    },
    {
      field: "fromRange",
      headerName: "From range",
      editable: true,
    },
    {
      field: "toRange",
      headerName: "To range",
      editable: true,
    },
    {
      field: "parameter",
      headerName: "Parameter",
      editable: true,
    },
    {
      field: "CMCPLUS",
      headerName: "Lower CMC",
      editable: true,
    },
    {
      field: "CMSMINUS",
      headerName: "Higher CMC",
      editable: true,
    },
    {
      field: "location",
      headerName: "Location",
      editable: true,
    },
    {
      field: "lc",
      headerName: "Least Count",
      editable: true,
    },
  ],
];
