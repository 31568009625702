var metric, imperial;

metric = {
  nH: {
    name: {
      singular: "Nanohenry",
      plural: "Nanohenries",
    },
    to_anchor: 1 / 1000000000,
  },
  μH: {
    name: {
      singular: "Microhenry",
      plural: "Microhenries",
    },
    to_anchor: 1 / 1000000,
  },
  mH: {
    name: {
      singular: "Millihenry",
      plural: "Millihenries",
    },
    to_anchor: 1 / 1000,
  },
  H: {
    name: {
      singular: "Henry",
      plural: "Henries",
    },
    to_anchor: 1,
  },
  kH: {
    name: {
      singular: "Kilohenry",
      plural: "Kilohenries",
    },
    to_anchor: 1000,
  },
};

imperial = {
  inH: {
    name: {
      singular: "Inch Henry",
      plural: "Inch Henries",
    },
    to_anchor: 1 / 12, // This is an approximate value, adjust based on specific use case if necessary
  },
  ftH: {
    name: {
      singular: "Foot Henry",
      plural: "Foot Henries",
    },
    to_anchor: 1, // Foot Henry could be approximated similarly, use correct conversion factor if necessary
  },
  miH: {
    name: {
      singular: "Mile Henry",
      plural: "Mile Henries",
    },
    to_anchor: 5280, // Miles in terms of inductance
  },
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "H",
      ratio: 1 / 1,
    },
    imperial: {
      unit: "ftH",
      ratio: 1,
    },
  },
};
